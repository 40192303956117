.animated {
    animation-duration: .8192s;
    animation-fill-mode: backwards;
    transform-style: preserve-3d;
}
@keyframes intro {
    from {
        opacity: 0;
        transform: perspective(500px) translate3d(0,0,-50px);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.intro {
    animation: intro 1s ease-out;
}
@keyframes enterRight {
    from {
        opacity: 0;
        transform: perspective(500px) translate3d(20%,0,0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.enterRight {
    animation-name: enterRight;
}
@keyframes enterLeft {
    from {
        opacity: 0;
        transform: perspective(500px) translate3d(-20%,0,0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.enterLeft {
    animation-name: enterLeft;
}
@keyframes exitRight {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: perspective(500px) translate3d(100%,0,-100px);
    }
}
.exitRight {
    animation-name: exitRight;
}
@keyframes exitLeft {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: perspective(500px) translate3d(-100%,0,-100px);
    }
}
.exitLeft {
    animation-name: exitLeft;
}
