.next-steps .fa-link {
    margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
    margin-bottom: 0;
}

.next-steps .col-md-5 {
    margin-bottom: 3rem;
}

@media (max-width: 768px) {
    .next-steps .col-md-5 {
        margin-bottom: 0;
    }
}

.spinner {
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 7rem;
    height: 50vh;
    /*width: 100vw;*/
    background-color: white;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.result-block-container .result-block {
    opacity: 1;
}

.logo {
    background-image: url("./assets/atlat_symbol.svg");
    width: 3rem;
    height: 3.2rem;
    background-repeat: no-repeat;
    background-size: cover;
}

@font-face {
    font-family: 'Lufga';
    src: url("./assets/fonts/Lufga-Regular.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}
